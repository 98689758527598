import React from "react";
import {useUpdateHeaderTitle} from "../hooks";
import {ExtraButtons} from "../components/extra-buttons";
import {Header} from "../components/layout/header";
import styled from "styled-components";
import {Table as AntTable} from "antd";
import {useGetData} from "../pages-lib/achievements/model";
import {VisibilityProvider} from "../components/visibility-provider";
import {EditDrawer} from "../pages-lib/achievements/edit-drawer";
import {useManageItems} from "../pages-lib/achievements/model/use-manage-items";
import {getColumns} from "../pages-lib/achievements/table-columns";

export default function Achievements() {

    const [createDrawerState, setCreateDrawerState] = React.useState(false);
    const [editDrawerState, setEditDrawerState] = React.useState(false);

    const [taskType, setTaskType] = React.useState(false);

    const {dataItems, dataColumnsItems, handleTableChange, refetch: getItems, isLoading, pagination, options} = useGetData()

    const { editData, onSaveEdit, onCreate, onDelete, onEditItem, resetData} = useManageItems(getItems, onSuccessEdit, onSuccessCreate )

    const headerTitle = useUpdateHeaderTitle({
        items: dataItems,
        defaultTitle: "0 Объектов",
        arrType: ["Запись", "Зиписи", "записей"],
    })

    const columns = getColumns(onEditItem, onDelete)

    function onSuccessCreate() {
        setCreateDrawerState(true)
    }

    function onSuccessEdit() {
        setEditDrawerState(true)
    }

    return (
        <>
            <Header
                title={headerTitle}
                extra={
                    <ExtraButtons
                        onCreate={() => setCreateDrawerState(true)}
                        // onFilter={() => setFilterDrawerState(!filterDrawerState)}
                        // onSort={() => setSortableDrawerState(!sortableDrawerState)}
                        titles={{ onCreate: "Добавить запись" }}
                    />
                }
            />

            <StyledTable
                dataSource={dataColumnsItems}
                columns={columns}
                loading={isLoading}
                onChange={handleTableChange}
                pagination={pagination}
            />

            <VisibilityProvider visible={createDrawerState}>
                <EditDrawer
                    title={"Создание записи"}
                    initialValues={false}
                    onClose={() => setCreateDrawerState(false)}
                    onSave={onCreate}
                    typeOptions={options.taskTypesOptions}
                    categoryOptions={options.categoryOptions}
                    pointOptions={options.pointOptions}
                    userStatusOptions={options.userStatusOptions}
                />
            </VisibilityProvider>

            <VisibilityProvider visible={editDrawerState}>
                <EditDrawer
                    title={"Редактирование записи"}
                    initialValues={editData}
                    onClose={() => setEditDrawerState(false)}
                    onSave={onSaveEdit}
                    typeOptions={options.taskTypesOptions}
                    categoryOptions={options.categoryOptions}
                    pointOptions={options.pointOptions}
                    userStatusOptions={options.userStatusOptions}
                />
            </VisibilityProvider>

        </>
    )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`
