import React from "react";
import {
    Col,
    DatePicker,
    Divider,
    Drawer,
    Form,
    Input, Popconfirm,
    Row, Switch,
    Typography,
    Upload
} from "antd";
import { Button } from "src/components/actions/button";
import {CloseSvg, RotateClockwiseSvg, SaveSvg, TrashSvg} from "src/components/Svg";
import { useAppData } from "src/store/app-data";
import {
    getInitialValues,
    formatGuidValidation, getFormData, getFormattedLangData, getNestedFormData
} from "src/utils/helpers";
import styled from "styled-components";
import {MinusCircleOutlined, PlusOutlined, UploadOutlined} from "@ant-design/icons";
import {useImage} from "../../hooks";
import moment from "moment/moment";

const { Title } = Typography
export function EditDrawer({
                             title,
                             initialValues,
                             onClose,
                             onSave,
                           }) {

  const layoutConfig = useAppData((state) => state.layoutConfig);

  const [allUsers, setAllUsers] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const [form] = Form.useForm();


    const { getImage } = useImage()


  function prepareData(values) {
      const file = values.file ? getImage(values.file) : null;

    const data = {
        mailingType: "pushByToken",
        allUsers: allUsers ? 1 : 0,
        enableDefaultSounds: 1,
        title: values.title,
        body: values.body,
        availableAt: moment(values.availableAt).format("YYYY-MM-DDTHH:mm:ssZ"),
        ...(file && {file: file}),
    };


    return data;
  }

  async function onSubmit(values) {
        setIsLoading(true)

        const baseData = prepareData(values)

        const formData = getFormData({
            values: baseData,
            baseData: baseData.file
        })

        let formDataWithNestedFormData = formData;
        if (values.userGuids) {
            const data = getFormattedLangData(values)

            const dataWithNestedItem = {
                userGuids: data.userGuids,
            }

            formDataWithNestedFormData = getNestedFormData(
                dataWithNestedItem,
                formData
            )
        }

        try {
            await onSave(formDataWithNestedFormData);
            onClose();
            form.resetFields();
        } catch (Error) {
            console.error("ошибка")
        } finally {
            setIsLoading(false)
        }
    }

  function handleChangeAllUsers(e) {
      setAllUsers(e)
  }

  function handleClose() {
    onClose();
    form.resetFields();
  }

  const id = initialValues?.id ?? "new";

  return (
      <DrawerEdit
          width={layoutConfig.isMobile ? "90%" : "80%"}
          title={title}
          onClose={handleClose}
          open
      >
        <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSubmit(values)}>

            <StyledFormItem
                label={"Заголовок"}
                key={id + "_title"}
                name={"title"}
                rules={[
                    {
                        required: true,
                        message: "Введите Заголовок",
                    },
                ]}
            >
                <Input maxLength={255}/>
            </StyledFormItem>

            <StyledFormItem
                label={"Тело сообщения"}
                key={id + "_body"}
                name={"body"}
                rules={[
                    {
                        required: true,
                        message: "Введите текст",
                    },
                ]}
            >
                <Input maxLength={255} />
            </StyledFormItem>

            <StyledFormItem
                label={"Дата и время отправки"}
                key={id + "_availableAt"}
                name={"availableAt"}
                rules={[
                    {
                        required: true,
                        message: "Введите дату отправки",
                    },
                ]}
            >
                <DatePicker
                    showTime
                />
            </StyledFormItem>

            <StyledFormItem
                label={"Отправить всем"}
                key={id + "_allUsers"}
                name={"allUsers"}
            >
                <Switch
                    onChange={handleChangeAllUsers}
                    checked={allUsers}
                />
            </StyledFormItem>

            {!allUsers && (
                <>
                    <Divider orientation="left">Загрузка файлом</Divider>

                    <StyledFormItem
                        name={"file"}
                        label={"Файл"}
                        key={id + "_file"}
                    >
                        <Upload
                            accept={".csv"}
                            beforeUpload={() => false}
                            maxCount={1}
                        >
                            <Button icon={<UploadOutlined />}>Нажмите для загрузки</Button>
                        </Upload>
                    </StyledFormItem>


                    <Divider orientation="left">Добавить по GUID</Divider>

                    <Form.List name="userGuids">
                        {(fields, { add, remove }) => (
                            <>
                                <RowFlex>
                                    {fields.map(({ key, ...field }, index) => (
                                        <ColumnFlex key={index}>
                                            <RowTitle level={4}>
                                                GUID {index + 1}{" "}
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    style={{ marginLeft: "12px" }}
                                                    onClick={() => {
                                                        remove(index)
                                                    }}
                                                />
                                            </RowTitle>
                                            <Form.Item required={false} key={key}>
                                                <StyledFormItem
                                                    style={{ width: "300px" }}
                                                    {...field}
                                                    key={id + "_key"}
                                                    name={[index]}
                                                    rules={[
                                                        (formInstance) => ({
                                                            message: "Введите guid!",
                                                            required:
                                                            formInstance.getFieldValue("additionalData")?.index,
                                                        }),
                                                        {
                                                            validator: formatGuidValidation,
                                                        },
                                                    ]}
                                                >
                                                    <Input type={"text"} style={{ width: "300px" }} placeholder={"guid"} />
                                                </StyledFormItem>
                                            </Form.Item>
                                        </ColumnFlex>
                                    ))}
                                </RowFlex>

                                <Row gutter={28}>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                style={{ width: "100%" }}
                                                icon={<PlusOutlined />}
                                            >
                                                Добавить поля
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form.List>
                </>
            )}

          <ButtonRow>
            <ButtonCol>
              <Button
                  danger
                  onClick={() => {
                    if (initialValues) {
                      form.setFieldsValue(getInitialValues(initialValues));
                      return;
                    }
                    form.resetFields();
                  }}
                  icon={<RotateClockwiseSvg />}
              >
                Вернуть
              </Button>
            </ButtonCol>
            <ButtonCol>
              <Button onClick={handleClose} icon={<CloseSvg />}>
                Закрыть
              </Button>
            </ButtonCol>
            <ButtonCol>
                {allUsers ? (
                    <Popconfirm
                        placement="left"
                        title="Действительно хотите отправить уведомление ВСЕМ?"
                        onConfirm={() => form.submit()}
                        onCancel={() => stopPropagation}
                    >
                        <Button icon={<SaveSvg />} onClick={() => stopPropagation} type="primary">
                            Сохранить
                        </Button>
                    </Popconfirm>
                ) : (
                    <Button disabled={!isLoading} icon={<SaveSvg />} type="primary" htmlType="submit">
                        Сохранить
                    </Button>
                )}
            </ButtonCol>
          </ButtonRow>
        </FormEdit>
      </DrawerEdit>
  );
}

const DrawerEdit = styled(Drawer)``;

const FormEdit = styled(Form)``;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`;

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`;

const RowFlex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 8px;
  flex-wrap: wrap;
`

const ColumnFlex = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 8px;
`

const RowTitle = styled(Title)`
  margin-bottom: 20px;
`

const stopPropagation = (e) => {
    e?.stopPropagation()
}
