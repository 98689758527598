import React from "react";
import {Col, DatePicker, Drawer, Form, Image, Input, InputNumber, Radio, Row, Select, Tabs, Upload} from "antd";
import { Button } from "src/components/actions/button";
import { CloseSvg, RotateClockwiseSvg, SaveSvg } from "src/components/Svg";
import { TranslatableInput } from "src/components/Translatable";
import { useAppData } from "src/store/app-data";
import {
    emailValidation, formatEmailValidation, formatPhoneValidation,
    getFormattedLangData,
    getFormData,
    getInitialValues,
    getNestedFormData,
    getOldImage, phoneValidation,
    whiteSpaceValidation
} from "src/utils/helpers";
import styled from "styled-components";
import {PlusOutlined} from "@ant-design/icons";
import {useImage} from "../../hooks";
import moment from "moment/moment";

export function EditDrawer({
                               title,
                               initialValues,
                               onClose,
                               onSave,
                           }) {

    const layoutConfig = useAppData((state) => state.layoutConfig);

    const [form] = Form.useForm();

    const { getImage, imageUpdated, onPreview, onChange, preview } = useImage()

    const disabledDate = (current) => {
        return current && current >= moment();
    };

    React.useEffect(() => {
        if (initialValues) {
            let values = {
                id: initialValues.id,
                phone: initialValues.phone ?? null,
                email: initialValues.email ?? null,
                birthdayDate: initialValues.dashboardUserInfo?.birthdayDate ? moment(initialValues.dashboardUserInfo.birthdayDate, "YYYY-MM-DD") : null,
            }
            form.setFieldsValue(getInitialValues(values));
        }
    }, [initialValues]);

    function prepareData(values) {
        const id = initialValues?.id

        const data = {
            ...(id && { id, _method: "PUT", birthdayDate: moment(values.birthdayDate).format("YYYY-MM-DDTHH:mm:ssZ") }),
            phone: values.phone,
            email: values.email,
            name: values.name ?? null,
        };

        return data;
    }


    async function onSubmit(values) {

        const baseData = prepareData(values)

        const formData = getFormData({
            values: baseData,
            photo: baseData.imageUpdated && baseData.photo,
        })

        const data = getFormattedLangData(values)


        await onSave(formData, values?.id);
        onClose();
        form.resetFields();
    }

    function handleClose() {
        onClose();
        form.resetFields();
    }

    const id = initialValues?.id ?? "new";

    return (
        <DrawerEdit
            width={layoutConfig.isMobile ? "90%" : "80%"}
            title={title}
            onClose={handleClose}
            open
        >
            <FormEdit form={form} layout={"vertical"} onFinish={(values) => onSubmit(values)}>


                <StyledFormItem
                    label={"Телефон"}
                    key={id + "_phone"}
                    name={"phone"}
                    rules={[
                        {
                            required: true,
                            message: "Введите номер",
                        },
                        {
                            validator: (id === "new" ? phoneValidation : formatPhoneValidation)
                        },
                    ]}
                >
                    <Input placeholder={"Формат: 79*********"}/>
                </StyledFormItem>


                <StyledFormItem
                    label={"E-mail"}
                    key={id + "_email"}
                    name={"email"}
                    rules={[
                        {
                            required: true,
                        },
                        {
                            validator: (id === "new" ? emailValidation : formatEmailValidation)
                        }
                    ]}
                >
                    <Input type="email" placeholder={"name@domain.com"} />
                </StyledFormItem>

                {id !== "new" && (
                    <>
                        <StyledFormItem
                            label={"Дата рождения"}
                            key={id + "_birthdayDate"}
                            name={"birthdayDate"}
                            rules={[
                                {
                                    required: true,
                                    message: "Введите дату рождения",
                                },
                            ]}
                        >
                            <DatePicker
                                disabledDate={disabledDate}
                            />
                        </StyledFormItem>
                    </>
                )}

                {id === "new" && (
                    <>
                        <StyledFormItem
                            label={"Имя"}
                            key={id + "_name"}
                            name={"name"}
                            rules={[
                                {
                                    required: false,
                                    message: "Введите пароль",
                                },
                            ]}
                        >
                            <Input />
                        </StyledFormItem>
                    </>
                )}


                <ButtonRow>
                    <ButtonCol>
                        <Button
                            danger
                            onClick={() => {
                                if (initialValues) {
                                    form.setFieldsValue(getInitialValues(initialValues));
                                    return;
                                }
                                form.resetFields();
                            }}
                            icon={<RotateClockwiseSvg />}
                        >
                            Вернуть
                        </Button>
                    </ButtonCol>
                    <ButtonCol>
                        <Button onClick={handleClose} icon={<CloseSvg />}>
                            Закрыть
                        </Button>
                    </ButtonCol>
                    <ButtonCol>
                        <Button icon={<SaveSvg />} type="primary" htmlType="submit">
                            Сохранить
                        </Button>
                    </ButtonCol>
                </ButtonRow>
            </FormEdit>
        </DrawerEdit>
    );
}

const DrawerEdit = styled(Drawer)``;

const FormEdit = styled(Form)``;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`;

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`;

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`;

const StyledImage = styled(Image)`
  display: none;
`

