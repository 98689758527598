import React from "react"
import {
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Image,
  Input, Popconfirm,
  Radio,
  Row,
} from "antd"
import { Button } from "src/components/actions/button"
import {CloseSvg, TrashSvg} from "src/components/Svg"
import { useAppData } from "src/store/app-data"
import styled from "styled-components"
import {SearchOutlined} from "@ant-design/icons";
import {getPaymentTypes} from "../../utils";

const DATE_FORMAT = "DD.MM.YYYY HH:mm"

export function EditDrawer({ title, initialValues, onClose, handleSetChangeValue, openChangePaymentDrawer, sendPaymentUrlToEmail }) {
  const layoutConfig = useAppData((state) => state.layoutConfig)

  const [form] = Form.useForm()



  React.useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues)
    }
  }, [initialValues])

  function handleClose() {
    onClose()
    form.resetFields()
  }

  const id = initialValues?.orderId ?? "new"

  function onPaymentTypeChange(id) {
    handleSetChangeValue({
      orderId: id,
      paymentTypeId: initialValues.dashboardOrder.paymentTypeId
    })
    openChangePaymentDrawer()
  }

  function sendPaymentUrlForEmail(id, type) {
    const newForm = new FormData;
    newForm.append("orderId", id)
    newForm.append("typeNotifications",  type)
    sendPaymentUrlToEmail(newForm)
  }

  return (
    <DrawerEdit
      width={layoutConfig.isMobile ? "90%" : "80%"}
      title={title}
      onClose={handleClose}
      open
    >
      <FormEdit form={form} layout={"vertical"}>
        <NoCursorItem>
          <Row gutter={24} style={{ marginBottom: "24px" }}>
            {initialValues.photos?.map((photo) => (
              <Col className="gutter-row" span={3} key={photo.id}>
                <Image key={photo.id} width={200} src={photo.link} />
              </Col>
            ))}
          </Row>

          <Divider orientation="left">Основная информация</Divider>

          <Row gutter={24} style={{ marginBottom: "16px" }}>
            <Col className="gutter-row" key={"deliveryStatus"} span={6}>
              <StyledFormItem
                label="Статус доставки"
                key={id + "_deliveryStatus"}
                name={["dashboardOrder", "deliveryStatus"]}
              >
                <Input placeholder="Нет статуса" />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"deliveryType"} span={6}>
              <StyledFormItem
                label="Способ получения"
                key={id + "_deliveryType"}
                name={["dashboardOrder", "deliveryType"]}
              >
                <Input />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"paymentType"} span={6}>
              <StyledFormItem
                label="Тип оплаты"
                key={id + "_paymentType"}
                name={["dashboardOrder", "paymentType"]}
              >
                <Input />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"descriptionToPayment"} span={6}>
              <StyledFormItem
                label="Описание к оплате"
                key={id + "_descriptionToPayment"}
                name={["dashboardOrder", "descriptionToPayment"]}
              >
                <Input placeholder="Нет описания" />
              </StyledFormItem>
            </Col>
          </Row>

          <Row gutter={24} style={{ marginBottom: "16px" }}>
            <Col className="gutter-row" key={"deliveryAddress"} span={12}>
              <StyledFormItem
                  label="Адрес доставки/получения"
                  key={id + "_deliveryAddress"}
                  name={["dashboardOrder", "deliveryAddress"]}
              >
                <Input placeholder="Нет aдресса" />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"changePaymentType"} span={6}>
              <CursorItem>
                <Popconfirm
                    placement="left"
                    title="Действительно хотите сменить тип оплаты?"
                    onConfirm={() => onPaymentTypeChange(id)}
                    onCancel={() => stopPropagation}
                >
                  <Button style={{ marginTop: "25px" }}  type="primary" onClick={stopPropagation}>
                    Сменить тип оплаты
                  </Button>
                </Popconfirm>
              </CursorItem>
            </Col>
          </Row>

          <Row gutter={24} style={{ marginBottom: "16px" }}>
            <Col className="gutter-row" key={"email"} span={6}>
              <StyledFormItem
                label="Email"
                key={id + "_email"}
                name={["dashboardOrder", "email"]}
              >
                <Input placeholder="Нет email" />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"externalGuid"} span={6}>
              <StyledFormItem
                label="externalGuid"
                key={id + "_externalGuid"}
                name={["dashboardOrder", "externalGuid"]}
              >
                <Input placeholder="Нет externalGuid" />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"outNumber"} span={6}>
              <StyledFormItem
                label="Внешний номер"
                key={id + "_outNumber"}
                name={["dashboardOrder", "outNumber"]}
              >
                <Input placeholder="Нет внешнего номера" />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"innerId"} span={6}>
              <StyledFormItem
                label="Внутреннее id"
                key={id + "_innerIde"}
                name={["dashboardOrder", "innerId"]}
              >
                <Input placeholder="Нет id" />
              </StyledFormItem>
            </Col>
          </Row>

          <Row gutter={24} style={{ marginBottom: "16px" }}>
            <Col className="gutter-row" key={"commentToOrder"} span={12}>
              <StyledFormItem
                label="Комментарий к заказу"
                key={id + "_commentToOrder"}
                name={["dashboardOrder", "commentToOrder"]}
              >
                <Input placeholder="Нет комментария" />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"commentToAddress"} span={12}>
              <StyledFormItem
                label="Комментарий к адресу"
                key={id + "_commentToAddress"}
                name={["dashboardOrder", "commentToAddress"]}
              >
                <Input placeholder="Нет комментария" />
              </StyledFormItem>
            </Col>
          </Row>

          <Row gutter={24} style={{ marginBottom: "16px" }}>
            <Col className="gutter-row" key={"isNeedCreateClient"} span={4}>
              <StyledFormItem
                label="Необходимо добавить клиента"
                key={id + "_isNeedCreateClient"}
                name={["dashboardOrder", "isNeedCreateClient"]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={true}>Да</Radio.Button>
                  <Radio.Button value={false}>Нет</Radio.Button>
                </Radio.Group>
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"isNeedAccept"} span={4}>
              <StyledFormItem
                label="Требуется подтверждение"
                key={id + "_isNeedAccept"}
                name={["dashboardOrder", "isNeedAccept"]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={true}>Да</Radio.Button>
                  <Radio.Button value={false}>Нет</Radio.Button>
                </Radio.Group>
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"isNeedOutNumber"} span={4}>
              <StyledFormItem
                label="Нужен внешний номер"
                key={id + "_isNeedOutNumber"}
                name={["dashboardOrder", "isNeedOutNumber"]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={true}>Да</Radio.Button>
                  <Radio.Button value={false}>Нет</Radio.Button>
                </Radio.Group>
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"isDiscount"} span={4}>
              <StyledFormItem
                label="Есть ли скидка"
                key={id + "_isDiscount"}
                name={["dashboardOrder", "isDiscount"]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={true}>Да</Radio.Button>
                  <Radio.Button value={false}>Нет</Radio.Button>
                </Radio.Group>
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"isPaid"} span={4}>
              <StyledFormItem
                label="Оплачен"
                key={id + "_isPaid"}
                name={["dashboardOrder", "isPaid"]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={true}>Да</Radio.Button>
                  <Radio.Button value={false}>Нет</Radio.Button>
                </Radio.Group>
              </StyledFormItem>
            </Col>
          </Row>

          <Row gutter={24} style={{ marginBottom: "16px" }}>
            <Col className="gutter-row" key={"createdAt"} span={6}>
              <StyledFormItem
                label="Дата создания"
                key={id + "_createdAt"}
                name={["dashboardOrder", "createdAt"]}
              >
                <DatePicker
                  style={{
                    width: "100%",
                  }}
                  format={DATE_FORMAT}
                  placeholder="Нет даты"
                />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"updatedAt"} span={6}>
              <StyledFormItem
                label="Дата изменения"
                key={id + "_updatedAt"}
                name={["dashboardOrder", "updatedAt"]}
              >
                <DatePicker
                  style={{
                    width: "100%",
                  }}
                  format={DATE_FORMAT}
                  placeholder="Нет даты"
                />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"deletedAt"} span={6}>
              <StyledFormItem
                label="Дата удаления"
                key={id + "_deletedAt"}
                name={["dashboardOrder", "deletedAt"]}
              >
                <DatePicker
                  style={{
                    width: "100%",
                  }}
                  format={DATE_FORMAT}
                  placeholder="Нет даты"
                />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"deliveryDate"} span={6}>
              <StyledFormItem
                label="Дата доставки"
                key={id + "_deliveryDate"}
                name={["dashboardOrder", "deliveryDate"]}
              >
                <DatePicker
                  style={{
                    width: "100%",
                  }}
                  format={DATE_FORMAT}
                  placeholder="Нет даты"
                />
              </StyledFormItem>
            </Col>
          </Row>

          <Row gutter={24} style={{ marginBottom: "16px" }}>
            <Col className="gutter-row" key={"otherPhone"} span={4}>
              <StyledFormItem
                label="Другой телефон"
                key={id + "_otherPhone"}
                name={["dashboardOrder", "otherPhone"]}
              >
                <Input placeholder="Нет телефона" />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"otherName"} span={4}>
              <StyledFormItem
                label="Другое имя"
                key={id + "_otherName"}
                name={["dashboardOrder", "otherName"]}
              >
                <Input placeholder="Нет имени" />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"innerId"} span={4}>
              <StyledFormItem
                label="Id для онлайн оплаты"
                key={id + "_onlinePaymentId"}
                name={["dashboardOrder", "onlinePaymentId"]}
              >
                <Input placeholder="Нет payment id" />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"paymentUrl"} span={8}>
              <CursorItem>
                <StyledFormItem
                  label="Url для оплаты"
                  key={id + "_paymentUrl"}
                  name={["dashboardOrder", "paymentUrl"]}
                >
                  <Input readOnly placeholder="Нет url" />
                </StyledFormItem>
              </CursorItem>
            </Col>

            <Col className="gutter-row" key={"isSendToCrm"} span={4}>
              <StyledFormItem
                label="Отправка в crm"
                key={id + "_isSendToCrm"}
                name={["dashboardOrder", "isSendToCrm"]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={true}>Да</Radio.Button>
                  <Radio.Button value={false}>Нет</Radio.Button>
                </Radio.Group>
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"totalPrice"} span={4}>
              <StyledFormItem
                label="Общая сумма, руб."
                key={id + "_totalPrice"}
                name={["dashboardOrder", "totalPrice"]}
              >
                <Input placeholder="0" />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"deliveryPrice"} span={4}>
              <StyledFormItem
                label="Цена доставки, руб."
                key={id + "_deliveryPrice"}
                name={["dashboardOrder", "deliveryPrice"]}
              >
                <Input placeholder="0" />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"totalPriceWithDiscount"} span={4}>
              <StyledFormItem
                label="Общая сумма без учета скидки, руб."
                key={id + "_totalPriceWithDiscount"}
                name={["dashboardOrder", "totalPriceWithDiscount"]}
              >
                <Input placeholder="0" />
              </StyledFormItem>
            </Col>

            {initialValues?.dashboardOrder?.paymentUrl && (
                <>
                  <Col className="gutter-row" key={"sendToEmail"} span={5}>
                    <CursorItem>
                      <Popconfirm
                          placement="left"
                          title="Отправить ссылку на оплату по email?"
                          onConfirm={() => sendPaymentUrlForEmail(id, "email")}
                          onCancel={() => stopPropagation}
                      >
                        <StyledSendButton type="primary" onClick={stopPropagation}>
                          Отправить ссылку на оплату по email
                        </StyledSendButton>
                      </Popconfirm>
                    </CursorItem>
                  </Col>

                  <Col className="gutter-row" key={"sendTosms"} span={5}>
                    <CursorItem>
                      <Popconfirm
                          placement="left"
                          title="Отправить ссылку на оплату по sms?"
                          onConfirm={() => sendPaymentUrlForEmail(id, "sms")}
                          onCancel={() => stopPropagation}
                      >
                        <StyledSendButton type="primary" onClick={stopPropagation}>
                          Отправить ссылку на оплату по sms
                        </StyledSendButton>
                      </Popconfirm>
                    </CursorItem>
                  </Col>
                </>
            )}

          </Row>
          <Row gutter={24} style={{ marginBottom: "16px" }}>
            <Col className="gutter-row" key={"emptyColl"} span={12}>
              <StyledFormItem
                  label="Email для сертификата"
                  key={id + "_emailForCertificate"}
                  name={["dashboardOrder", "emailForCertificate"]}
              >
                <Input placeholder="Отсутствует" />
              </StyledFormItem>
            </Col>
          </Row>

          <Divider orientation="left">Информация о покупателе</Divider>

          <Row gutter={24} style={{ marginBottom: "16px" }}>
            <Col className="gutter-row" span={4}>
              <StyledFormItem
                label="Имя"
                key={id + "_name"}
                name={["dashboardUser", "name"]}
              >
                <Input placeholder="Отсутствует" />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" span={4}>
              <StyledFormItem
                label="Фамилия"
                key={id + "_surname"}
                name={["dashboardUser", "surname"]}
              >
                <Input placeholder="Отсутствует" />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" span={4}>
              <StyledFormItem
                label="Номер телефона"
                key={id + "_phone"}
                name={["dashboardUser", "phone"]}
              >
                <Input placeholder="Отсутствует" />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" span={4}>
              <StyledFormItem
                label="Email"
                key={id + "_email"}
                name={["dashboardUser", "email"]}
              >
                <Input placeholder="Отсутствует" />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"externalGuid"} span={4}>
              <StyledFormItem
                label="externalGuid"
                key={id + "_externalGuid"}
                name={["dashboardUser", "externalGuid"]}
              >
                <Input placeholder="Отсутствует" />
              </StyledFormItem>
            </Col>

            <Col className="gutter-row" key={"isStaff"} span={4}>
              <StyledFormItem
                label="Сотрудник"
                key={id + "_isStaff"}
                name={["dashboardUser", "isStaff"]}
              >
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={true}>Да</Radio.Button>
                  <Radio.Button value={false}>Нет</Radio.Button>
                </Radio.Group>
              </StyledFormItem>
            </Col>
          </Row>

          <Divider orientation="left">Список товаров</Divider>

          <Form.List name="dashboardOrderItems">
            {(fields) =>
              fields.map(({ key, name, ...field }, index) => (
                <>
                  <Row
                    gutter={24}
                    style={{ marginBottom: "16px" }}
                    key={`dashboardOrderItems_${key}_${index}`}
                  >
                    <Col className="gutter-row" key={"name"} span={6}>
                      <DisabledFormItem
                        key={id + "_name"}
                        name={[name, "name"]}
                        {...field}
                      >
                        <Input placeholder="Отсутствует" bordered={false} />
                      </DisabledFormItem>
                    </Col>
                  </Row>
                  <Row
                    gutter={24}
                    style={{ marginBottom: "16px" }}
                    key={`dashboardOrderItems_${key}_${index}`}
                  >
                    <Col className="gutter-row" key={"count"} span={6}>
                      <StyledFormItem
                        label="Кол-во"
                        key={id + "_count"}
                        name={[name, "count"]}
                        {...field}
                      >
                        <Input placeholder="Отсутствует" />
                      </StyledFormItem>
                    </Col>
                    <Col className="gutter-row" key={"price"} span={6}>
                      <StyledFormItem
                        label="Цена, руб."
                        key={id + "_price"}
                        name={[name, "price"]}
                        {...field}
                      >
                        <Input placeholder="Отсутствует" />
                      </StyledFormItem>
                    </Col>
                    <Col className="gutter-row" key={"discount"} span={6}>
                      <StyledFormItem
                        label="Скидка, руб."
                        key={id + "_discount"}
                        name={[name, "discount"]}
                        {...field}
                      >
                        <Input placeholder="Отсутствует" />
                      </StyledFormItem>
                    </Col>
                    <Col className="gutter-row" key={"priceWithDiscount"} span={6}>
                      <StyledFormItem
                        label="Цена со скидкой, руб."
                        key={id + "_priceWithDiscount"}
                        name={[name, "priceWithDiscount"]}
                        {...field}
                      >
                        <Input placeholder="Отсутствует" />
                      </StyledFormItem>
                    </Col>

                    <Col className="gutter-row" key={"discountName"} span={6}>
                      <StyledFormItem
                        label="Наименование скидки"
                        key={id + "_discountName"}
                        name={[name, "discountName"]}
                        {...field}
                      >
                        <Input placeholder="Отсутствует" />
                      </StyledFormItem>
                    </Col>
                    <Col className="gutter-row" key={"totalPrice"} span={6}>
                      <StyledFormItem
                        label="Общая сумма, руб."
                        key={id + "_totalPrice"}
                        name={[name, "totalPrice"]}
                        {...field}
                      >
                        <Input placeholder="Отсутствует" />
                      </StyledFormItem>
                    </Col>
                    <Col
                      className="gutter-row"
                      key={"totalPriceWithDiscount"}
                      span={6}
                    >
                      <StyledFormItem
                        label="Общая сумма со скидкой, руб."
                        key={id + "_totalPriceWithDiscount"}
                        name={[name, "totalPriceWithDiscount"]}
                        {...field}
                      >
                        <Input placeholder="Отсутствует" />
                      </StyledFormItem>
                    </Col>
                    <Col className="gutter-row" key={"size"} span={6}>
                      <StyledFormItem
                        label="Размеры"
                        key={id + "_size"}
                        name={[name, "size"]}
                        {...field}
                      >
                        <Input placeholder="Отсутствует" />
                      </StyledFormItem>
                    </Col>
                    <Col className="gutter-row" key={"externalOrderUuid"} span={6}>
                      <StyledFormItem
                        label="Идентификатор cчета в crm"
                        key={id + "_externalOrderUuid"}
                        name={[name, "externalOrderUuid"]}
                        {...field}
                      >
                        <Input placeholder="Отсутствует" />
                      </StyledFormItem>
                    </Col>
                    <Col
                      className="gutter-row"
                      key={"externalOrderItemUuid"}
                      span={6}
                    >
                      <StyledFormItem
                        label="Идентификатор позиции в crm"
                        key={id + "_externalOrderItemUuid"}
                        name={[name, "externalOrderItemUuid"]}
                        {...field}
                      >
                        <Input placeholder="Отсутствует" />
                      </StyledFormItem>
                    </Col>
                    <Col className="gutter-row" key={"externalGuid"} span={6}>
                      <StyledFormItem
                        label="Внешний идентификатор"
                        key={id + "_externalGuid"}
                        name={[name, "externalGuid"]}
                        {...field}
                      >
                        <Input placeholder="Отсутствует" />
                      </StyledFormItem>
                    </Col>
                  </Row>
                </>
              ))
            }
          </Form.List>
        </NoCursorItem>
        <ButtonRow>
          <ButtonCol>
            <Button onClick={handleClose} icon={<CloseSvg />}>
              Закрыть
            </Button>
          </ButtonCol>
        </ButtonRow>
      </FormEdit>
    </DrawerEdit>
  )
}

const DrawerEdit = styled(Drawer)``

const FormEdit = styled(Form)``

const NoCursorItem = styled.div`
  pointer-events: none;
`


const CursorItem = styled.div`
  pointer-events: all;
`

const DisabledFormItem = styled(Form.Item)`
  margin-bottom: 4px;

  input {
    font-weight: 600;
    padding: 0;
    color: rgba(44, 53, 68, 0.85);
  }
`

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 16px;
`

const StyledCol = styled(Col)`
  margin-bottom: 16px;
`

const ButtonCol = styled(Col)`
  padding-bottom: 5px;
`

const ButtonRow = styled(Row)`
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  .ant-col {
    margin-left: 0.4em;
  }
`

const StyledImage = styled(Image)`
  display: none;
`

const stopPropagation = (e) => {
  e?.stopPropagation();
};

const StyledSendButton = styled(Button)`
  margin-top: 22px;
  height: auto;
  width: auto;
  white-space: normal;
`
