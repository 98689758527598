import React from "react"
import api from "src/api"
import {getArticleCategories, getCities, getPoints, getProductCategories} from "src/utils"

export function useGetData() {
  const [dataItems, setDataItems] = React.useState([])
  const [dataColumnsItems, setDataColumnsItems] = React.useState([])

  const [isLoading, setIsLoading] = React.useState(false)
  const [pagination, setPagination] = React.useState(null)

  const [taskTypesOptions, setTaskTypesOptions] = React.useState([])
  const [categoryOptions, setCategoryOptions] = React.useState([]);
  const [pointOptions, setPointOptions] = React.useState([]);
  const [userStatusOptions, setUserStatusOptions] = React.useState([]);

  React.useEffect(() => {
    getItems()
    getCategoryOptions()
    getTaskTypes()
    getPointOptions()
    getUserStatuses()
  }, [])

  async function getTaskTypes() {
    api.get("task-types").then(({ data }) => {
      const options = []
      data.items.forEach((item) => {
        options.push({
          label: item.name,
          value: item.id,
          slug: item.slug
        })
      })
      setTaskTypesOptions(options)
    })
  }

  async function getUserStatuses() {
    api.get("user-statuses-hints").then(({ data }) => {
      const options = []
      data.items.forEach((item) => {
        options.push({
          label: `${item.number} - ${item.name.find((el) => el.language === "ru").content}`,
          value: item.id,
        })
      })
      setUserStatusOptions(options)
    })
  }

  async function getCategoryOptions() {
    const data = await getProductCategories();

    const options = data.reduce((acc, item) => {
      const searchName = item?.name?.find((el) => el.language === "ru");
      return [
        ...acc,
        {
          label: searchName.content,
          value: item.id,
        },
      ];
    }, []);
    setCategoryOptions(options);
  }

  async function getPointOptions() {
    const data = await getPoints()

    const options = data.map((item) => ({
      label: item.name,
      value: item.id,
    }))
    setPointOptions(options)
  }

  const getItems = (page = 1, countOnPage = 20) => {
    setIsLoading(true)
    api.get(`loyalty-task?page=${page}&countOnPage=${countOnPage}`).then(({data}) => {
      setPagination({
        current: data.pagination.currentPage,
        pageSize: data.pagination.countOnPage,
        total: data.pagination.total,
      })
      updateItems(data.items)
    })


    setIsLoading(false)
  }

  function updateItems(items) {
    const newData = getFormattedItems(items)
    setDataColumnsItems(newData)
    setDataItems(items)
  }

  function handleTableChange(pagination) {
    getItems(pagination.current, pagination.pageSize)
  }

  function getFormattedItems(items) {
    const newItems = []

    items.forEach((item) => {
      let newItem = {
        key: item.id,
        id: item.id,
        name: item.name.find((el) => el.language === "ru").content,
        tag: item.tag.find((el) => el.language === "ru").content,
        isActive: item.isActive,
      }
      newItems.push(newItem)
    })

    return newItems
  }

  return {
    dataItems,
    dataColumnsItems,
    pagination,
    isLoading,
    handleTableChange,
    refetch: () => getItems(),
    options: { taskTypesOptions, categoryOptions, pointOptions, userStatusOptions },
  }
}
