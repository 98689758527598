import React from "react"
import { Table as AntTable } from "antd"
import { ExtraButtons } from "src/components/extra-buttons"
import { Header } from "src/components/layout/header"
import { VisibilityProvider } from "src/components/visibility-provider"
import { useUpdateHeaderTitle } from "src/hooks/use-update-header-title"
import { EditDrawer } from "src/pages-lib/orders/edit-drawer"
import { useGetOrders, useManageItems } from "src/pages-lib/orders/model"
import { getFormattedItems } from "src/pages-lib/orders/model/use-get-orders"
import { getColumns } from "src/pages-lib/orders/table-columns"
import {OrderFilers} from "src/pages-lib/orders/filters";
import styled from "styled-components"
import {OrderExpandableRow} from "../pages-lib/orders/order-expandable-row";
import {ChangePaymentDrawer} from "../pages-lib/orders/change-payment-drawer";

export default function Orders() {
  const [editDrawerState, setEditDrawerState] = React.useState(false);
  const [changePaymentDrawerState, setChangePaymentDrawerState] = React.useState(false);
  const [changePaymentValues, setChangePaymentValues] = React.useState(false);

  const {
      items,
      isLoading,
      pagination,
      options,
      refetch: getItems,
      handleTableChange,
      handleSearchTable,
      handlePaymentCheck,
      resendToCrm
  } = useGetOrders();
  const { editData, onEditItem, onSaveChange, sendPaymentUrlToEmail } = useManageItems(items, getItems, onSuccessEdit, options);

  const headerTitle = useUpdateHeaderTitle({
    items,
    defaultTitle: "0 Заказов",
    arrType: ["Заказ", "Заказы", "Заказов"],
  });


  const columns = getColumns(onEditItem)

  function onSuccessEdit() {
    setEditDrawerState(!editDrawerState)
  }

  function handleSetChangeValue(values) {
      setChangePaymentValues(values)
  }

  function closeChangePaymentDrawer() {
      setChangePaymentDrawerState(false)
  }

    function openChangePaymentDrawer() {
        setChangePaymentDrawerState(true)
    }

  return (
    <>
      <Header title={headerTitle} extra={<ExtraButtons />} />
      <OrderFilers handleSearch={ handleSearchTable } />
      <StyledTable
        dataSource={getFormattedItems(items)}
        columns={columns}
        pagination={pagination}
        loading={isLoading}
        onChange={handleTableChange}
        expandable={{
            expandedRowRender: (record) =>
                <OrderExpandableRow
                    data={record}
                    onPayment={handlePaymentCheck}
                    onSend={resendToCrm}
                />
        }}
      />

      <VisibilityProvider visible={editDrawerState}>
        <EditDrawer
          onClose={() => setEditDrawerState(!editDrawerState)}
          initialValues={editData}
          title={"Просмотр заказа"}
          handleSetChangeValue={handleSetChangeValue}
          openChangePaymentDrawer={openChangePaymentDrawer}
          sendPaymentUrlToEmail={sendPaymentUrlToEmail}
        />
      </VisibilityProvider>

      <VisibilityProvider visible={changePaymentDrawerState}>
          <ChangePaymentDrawer
              title={"Изменить тип оплаты"}
              onClose={closeChangePaymentDrawer}
              initialValues={changePaymentValues}
              paymentTypesOption={options.paymentTypes}
              handleSetChangeValue={handleSetChangeValue}
              onSaveChange={onSaveChange}
          />
      </VisibilityProvider>
    </>
  )
}

const StyledTable = styled(AntTable)`
  .row-can-view {
    cursor: pointer;
  }
`
