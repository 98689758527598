import React from "react"
import api from "src/api"
import {getProductCategories} from "../../../utils";

export function useGetPromoCodes() {
  const [pagination, setPagination] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [categoryOptions, setCategoryOptions] = React.useState([]);

  const [items, setItems] = React.useState([])
  const [userStatusOptions, setUserStatusOptions] = React.useState([]);

  React.useEffect(() => {
    getUserStatuses()
    getPromocodes()
    getCategoryOptions()
  }, [])

  async function getPromocodes(page = 1, countOnPage = 20, filters = "") {
    setIsLoading(true)
    try {
      const { data } = await api.get(`promo-codes?page=${page}&countOnPage=${countOnPage}${filters}`)

      setPagination({
        current: data.pagination.currentPage,
        pageSize: data.pagination.countOnPage,
        total: data.pagination.total,
      })
      updateItems(data.items)

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  function updateItems(items) {
    const newData = getFormattedItems(items)
    setItems(newData)
  }

  async function getCategoryOptions() {
    const data = await getProductCategories();

    const options = data.reduce((acc, item) => {
      const searchName = item?.name?.find((el) => el.language === "ru");
      return [
        ...acc,
        {
          label: searchName.content,
          value: item.id,
        },
      ];
    }, []);
    setCategoryOptions(options);
  }

  function handleTableChange(pagination) {
    getPromocodes(pagination.current, pagination.pageSize)
  }

  async function getUserStatuses() {
    api.get("user-statuses-hints").then(({ data }) => {
      const options = []
      data.items.forEach((item) => {
        options.push({
          label: `${item.number} - ${item.name.find((el) => el.language === "ru").content}`,
          value: item.id,
        })
      })
      setUserStatusOptions(options)
    })
  }

  function handleSearchTable(filters) {
    let filters_str = ""
    let count = 0;
    for (let [key, value] of Object.entries(filters)) {

      if(value) {
        switch (key) {
          case "promocode":
            filters_str += `&filters[${count}][t]=iL&filters[${count}][f]=name&filters[${count}][v]=${value}`
            break;
          case "code":
            filters_str += `&filters[${count}][t]=iL&filters[${count}][f]=code&filters[${count}][v]=${value}`
            break;
          case "activeFrom":
            filters_str += `&filters[${count}][t]=equal&filters[${count}][f]=active_from&filters[${count}][v]=${value}`
            break;
          case "activeTo":
            filters_str += `&filters[${count}][t]=equal&filters[${count}][f]=active_to&filters[${count}][v]=${value}`
            break;
        }
      }

      count++
    }
    getPromocodes(1,  20, filters_str)
  }

  function getFormattedItems(items) {
    return items.map((item) => {
      const blackList = item.blackList.map((element) => ({
        label: `${element.guid} - ${element.name}`,
        value: element.id,
      }))
      const whiteList = item.whiteList.map((element) => ({
        label: `${element.guid} - ${element.name}`,
        value: element.id,
      }))

      const categoryBlackList = [];

      item.categoryBlackList.forEach((element) => {
        categoryBlackList.push(element.id)
      })

      const categoryWhiteList = [];

      item.categoryWhiteList.forEach((element) => {
        categoryWhiteList.push(element.id)
      })

      const user = item.user != null
          ? {
            label: item.user?.phone,
            value: item.user?.id
          }
          : null;

      return {
        key: item.id,
        id: item.id,
        promocode: item.promocode,
        description: item.description,
        minPrice: item.minPrice,
        code: item.code,
        type: item.type,
        amount: item.amount,
        isActive: item.isActive,
        isPublic: item.isPublic,
        activeFrom: item.activeFrom,
        activeTo: item.activeTo,
        isDisposable: item.isDisposable,
        isDisposableForUser: item.isDisposableForUser,
        isForFirstOrder: item.isForFirstOrder,
        blackList: blackList,
        whiteList: whiteList,
        categoryWhiteList: categoryWhiteList,
        categoryBlackList: categoryBlackList,
        isPrivate: item.isPrivate,
        userId: user,
        statuses: item.statuses.map((element) => element.id)

      }
    })
  }

  return {
    items,
    refetch: () => getPromocodes(),
    pagination,
    isLoading,
    handleSearchTable,
    handleTableChange,
    options: { categoryOptions, userStatusOptions }
  }
}
