import React from "react";
import api from "src/api";
import { getPoints, getProductCategories } from "src/utils";

export function useGetCategories() {
  const [pagination, setPagination] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [categoryOptions, setCategoryOptions] = React.useState([]);

  async function getItems() {
    setIsLoading(true);
    try {
      const response = await api.get("group-product-categories");

      setPagination(response.data.pagination);
      setItems(response.data.items);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  React.useEffect(() => {
    getItems();
    getCategoryOptions();
  }, []);

  async function getCategoryOptions() {
    const data = await getProductCategories();

    const options = data.reduce((acc, item) => {
      const searchName = item?.name?.find((el) => el.language === "ru");
      return [
        ...acc,
        {
          label: searchName.content,
          value: item.id,
          disabled: !item.isActive,
        },
      ];
    }, []);
    setCategoryOptions(options);
  }

  return {
    items,
    pagination,
    isLoading,
    refetch: () => getItems(),
    options: { categoryOptions },
  };
}

export function getFormattedItems(items, categoryOptions) {
  const data = items.map((item) => ({
    id: item.id,
    name: item.name,
    nameRu: item.name?.find((el) => el.language === "ru")?.content,
    isActive: item.isActive,
    isTop: item.isTop,
    sortWeight: item.sortWeight,
    categories: item.productCategoryIds.map((id) => {
      const item = categoryOptions?.find((option) => option.value === id);
      return {
        label: item?.label,
        disabled: item?.disabled,
      };
    }),
  }));

  return data;
}
