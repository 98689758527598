import React from "react"
import { notification } from "antd"
import api from "src/api"
import { getOldImage } from "src/utils/helpers"
import moment from "moment";
import {DATE_FORMAT} from "../../../config";

export function useManageItems(getItems, onSuccessEdit, onSuccessCreate) {
  const [editData, setEditData] = React.useState(null)

  async function onEditItem(id) {
    const {data} = await api.get(`loyalty-task/${id}`)
    const item = formmatedEditData(data.task)
    setEditData(item)
    onSuccessEdit()
  }

  async function onCreate(values) {
    try {
      await api.post("loyalty-task", values)

      notification.success({ message: "Запись добавлена" })
      onSuccessCreate()
      getItems()
    } catch (error) {
      console.log("Ошибка добавления", error)
      notification.error({ message: "Ошибка добавления" })
      throw error
    }
  }

  async function onSaveEdit(values, id) {
    try {
      await api.post(`loyalty-task/${id}`, values)

      notification.success({ message: "Запись изменена" })
      getItems()
    } catch (error) {
      console.log("Ошибка изменения", error)
      notification.error({ message: "Ошибка изменения" })
      throw error
    }
  }

  async function onDelete(id) {
    try {
      await api.delete(`loyalty-task/${id}`)

      notification.success({ message: "Новость удалена" })
      getItems()
    } catch (error) {
      console.log("Ошибка удаления", error)
      notification.error({ message: "Ошибка удаления" })
      throw error
    }
  }

  function resetData() {
    setEditData(null)
  }

  function formmatedEditData(item) {
    return {
      ...item,
      startAt: item.startAt !== null ? moment(item.startedAt, DATE_FORMAT) : null,
      endAt: item.endAt !== null ? moment(item.endAt, DATE_FORMAT) : null,
      rewardIds:item.rewards.map((reward) => {
        return {
          label: reward.name,
          value: reward. id
        }
      }),
      ...(item.taskElements[0].productIds && {
        productIds: item.taskElements[0].productIds.map((product) => {
          return {
            label: product.name ?? "Название не пришло бека",
            value: product.id,
            key: product.id
          }
        })
      }),
      elementName: item.taskElements[0]?.name ?? null,
      typeId: item.taskElements[0].taskTypeId,
      count: item.taskElements[0].count,
      isOnApp: item.taskElements[0].isOnApp,
      isOnCashBox: item.taskElements[0].isOnCashBox,
      isOnDelivery: item.taskElements[0].isOnDelivery,
      isOnPickup: item.taskElements[0].isOnPickup,
      pointIds: item.taskElements[0].pointIds.length > 0 ? item.taskElements[0].pointIds : [],
      productCategoryId: item.taskElements[0].productCategoryId ?? null
    }
  }

  return { editData, resetData, onSaveEdit, onCreate, onDelete, onEditItem }
}
