import { useNavigate } from "react-router";
import { Badge, Menu } from "antd";
import {
  BrowserSvg,
  ColumnSvg,
  DiscountSvg,
  NewsSvg,
  CakeSvg,
  UsersSvg,
  BuilderSvg,
  TruckSvg,
  ProductSvg,
  OrderSvg,
  MapSvg
} from "src/components/Svg";
import styled from "styled-components";
import {
  BankOutlined,
  CreditCardOutlined,
  ExceptionOutlined,
  GiftOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
  UserSwitchOutlined,
  SettingOutlined
} from "@ant-design/icons";
import React from "react";

export function NavMenu() {
  let currentSvgProps = {
    stroke: "currentColor",
    strokeWidth: 1.5,
    size: 20,
  };

  const navigate = useNavigate();

  const menuItems = [
    {
      icon: UsersSvg(currentSvgProps),
      onClick: () => navigate("/dashboard/clients"),
      key: "/dashboard/clients",
      title: "Клиенты",
      label: "Клиенты",
    },
    {
      icon:  <MessageOutlined style={{ fontSize: "16px"}}/>,
      onClick: () => navigate("/dashboard/push"),
      key: "/dashboard/push",
      title: "Пуши",
      label: "Пуши",
    },

    {
      icon: ProductSvg(currentSvgProps),
      key: "sub_0",
      label: "Продукты",
      children: [
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/product-categories"),
          key: "/dashboard/product-categories",
          title: "Категории",
          label: "Категории",
        },
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/products-groups"),
          key: "/dashboard/products-groups",
          title: "Группы категорий",
          label: "Группы категорий",
        },
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/products"),
          key: "/dashboard/products",
          title: "Товары",
          label: "Товары",
        },
        {
          icon: MapSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/points-product"),
          key: "/dashboard/points-product",
          title: "Доступность",
          label: "Доступность",
        },
        {
          icon: TruckSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/product-constructor"),
          key: "/dashboard/product-constructor",
          title: "Добавки",
          label: "Добавки",
        },
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/certificates"),
          key: "/dashboard/certificates",
          title: "Сертификаты",
          label: "Сертификаты",
        },
      ],
    },
    {
      icon: MapSvg(currentSvgProps),
      onClick: () => navigate("/dashboard/points"),
      key: "/dashboard/points",
      title: "Точки",
      label: "Точки",
    },
    {
      icon: <CreditCardOutlined />,
      onClick: () => navigate("/dashboard/payment-accounts"),
      key: "/dashboard/payment-accounts",
      title: "Платежные акк.",
      label: "Платежные акк.",
    },
    {
      icon: OrderSvg(currentSvgProps),
      onClick: () => navigate("/dashboard/orders"),
      key: "/dashboard/orders",
      title: "Заказы",
      label: "Заказы",
    },
    {
      icon: <BankOutlined />,
      onClick: () => navigate("/dashboard/order-hold-payment"),
      key: "/dashboard/order-hold-payment",
      title: "Оплаты в холде",
      label: "Оплаты в холде",
    },
    {
      icon: <SettingOutlined />,
      key: "sub_1",
      label: "Настройки",
      children: [
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/delivery-setting"),
          key: "/dashboard/delivery-setting",
          title: "Настройки доставки",
          label: "Настройки доставки",
        },
      ],
    },
    {
      icon: DiscountSvg(currentSvgProps),
      onClick: () => navigate("/dashboard/promo-codes"),
      key: "/dashboard/promo-codes",
      label: "Промокоды",
    },
    {
      icon: BrowserSvg(currentSvgProps),
      key: "sub_2",
      label: "Главная",
      children: [
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/live-love-work"),
          key: "/dashboard/live-love-work",
          title: "Больше чем булочки",
          label: "Больше чем булочки",
        },
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/stories"),
          key: "/dashboard/stories",
          title: "Истории",
          label: "Истории",
        },
      ],
    },
    {
      icon: NewsSvg(currentSvgProps),
      key: "sub_3",
      title: "Акции и новости",
      label: "Акции и новости",
      children: [
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/promotion-categories"),
          key: "/dashboard/promotion-categories",
          title: "Категории акций",
          label: "Категории акций",
        },
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/promotions"),
          key: "/dashboard/promotions",
          title: "Акции",
          label: "Акции",
        },
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/article-categories"),
          key: "/dashboard/article-categories",
          title: "Категории новостей",
          label: "Категории новостей",
        },
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/articles"),
          key: "/dashboard/articles",
          title: "Новости",
          label: "Новости",
        },
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/event-categories"),
          key: "/dashboard/event-categories",
          title: "Категории мероприятий",
          label: "Категории мероприятий",
        },
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/events"),
          key: "/dashboard/events",
          title: "Мероприятия",
          label: "Мероприятия",
        },
      ],
    },
    {
      icon: <ExceptionOutlined />,
      onClick: () => navigate("/dashboard/onboarding"),
      key: "/dashboard/onboarding",
      label: "Онбординг",
    },
    {
      icon: <GiftOutlined />,
      key: "sub_4",
      label: "Система лояльности",
      children: [
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/achievements"),
          key: "/dashboard/achievements",
          title: "Квесты",
          label: "Квесты",
        },
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/rewards"),
          key: "/dashboard/rewards",
          title: "Награды",
          label: "Награды",
        },
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/user-status-groups"),
          key: "/dashboard/user-status-groups",
          title: "Группы статусов",
          label: "Группы статусов",
        },
        {
          icon: ColumnSvg(currentSvgProps),
          onClick: () => navigate("/dashboard/user-statuses"),
          key: "/dashboard/user-statuses",
          title: "Статусы",
          label: "Статусы",
        }
      ],
    },
    {
      icon: <QuestionCircleOutlined />,
      onClick: () => navigate("/dashboard/faq"),
      key: "/dashboard/faq",
      label: "FAQ",
    },
  ];

  return <StyledMenu mode="inline" inlineIndent={0} items={menuItems} />;
}
const StyledBadge = styled(Badge)`
  &&& {
    font-weight: 700;
    margin-left: 5px;
    font-size: 10px;
    line-height: 17px;
    height: 17px;
    overflow-x: clip !important;
    margin-top: -3px;
  }
`;

const StyledMenu = styled(Menu)`
  &&& {
    border-right: 0;
    display: flex;
    flex-direction: column;
    margin: 4px 0;
  }
`;
